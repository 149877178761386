import { MARKETPLACE_URL_BASE, mktClient } from "../../../common/libs/axiosClient";
import { AssignPlatformToRightOfUse, CreateRightOfUseRequest, PlatformAssignment, RightOfUse } from "./RightOfUse";
import { ONLINE_SERVICES_URL } from "../onlinedienste/onlineDiensteApi";

interface RightOfUseRequestParams {
  operatorId?: string;
  producerId?: string;
}
export const USAGE_RIGHTS_URL = `${MARKETPLACE_URL_BASE}/right-of-use`;
export const PLATFORM_ASSIGNMENT_URL = ({ rightOfUseId, areaOfUseId }: { rightOfUseId: string; areaOfUseId: string }) =>
  `${MARKETPLACE_URL_BASE}/right-of-use/${rightOfUseId}/area-of-use/${areaOfUseId}/platform-assignment`;
export const deleteOnlineServiceById = (id: string) => mktClient.delete(`${ONLINE_SERVICES_URL.toString()}/${id}`);
export const fetchRightsOfUse = (params?: RightOfUseRequestParams) =>
  mktClient.get<RightOfUse[]>(USAGE_RIGHTS_URL.toString(), { params }).then<RightOfUse[]>((response) => response.data);

export const fetchRightOfUseByIdAndOperator = (id: string) =>
  mktClient
    .get<RightOfUse>(`${MARKETPLACE_URL_BASE}/right-of-use/operator/${id}`)
    .then<RightOfUse>((response) => response.data);
export const fetchRightOfUseByIdAndProducer = (id: string, selectedOrganization: string) =>
  mktClient
    .get<RightOfUse>(`${MARKETPLACE_URL_BASE}/right-of-use/producer/${selectedOrganization}/${id}`)
    .then<RightOfUse>((response) => response.data);

export const fetchPlatformAssignment = ({
  operatorId,
  rightOfUseId,
  areaOfUseId
}: {
  operatorId: string | null;
  rightOfUseId: string;
  areaOfUseId: string;
}) =>
  mktClient
    .get<PlatformAssignment>(PLATFORM_ASSIGNMENT_URL({ rightOfUseId, areaOfUseId }), { params: { operatorId } })
    .then<PlatformAssignment>((response) => response.data);

export const updatePlatformAssignment = ({
  operatorId,
  rightOfUseId,
  areaOfUseId,
  platformAssignment
}: {
  operatorId: string | null;
  rightOfUseId: string;
  areaOfUseId: string;
  platformAssignment: PlatformAssignment;
}) =>
  mktClient
    .put<PlatformAssignment>(PLATFORM_ASSIGNMENT_URL({ rightOfUseId, areaOfUseId }), platformAssignment, {
      params: { operatorId }
    })
    .then<PlatformAssignment>((response) => response.data);

export const addRightOfUse = (body: CreateRightOfUseRequest) => mktClient.post<void>(USAGE_RIGHTS_URL.toString(), body);
export const deleteRightOfUseById = ({ id, areaOfUseId }: { id: string; areaOfUseId: string }) =>
  mktClient.delete(`${USAGE_RIGHTS_URL.toString()}/${id}/${areaOfUseId}`);

export const updateRightOfUse = (body: AssignPlatformToRightOfUse[]) =>
  mktClient.patch<void>(`${USAGE_RIGHTS_URL.toString()}/platform`, body);
