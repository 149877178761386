import { useTranslation } from "react-i18next";
import { PricingModel, AfterUseModel } from "./RightOfUse";

export function usePricingModelMap() {
  const { t } = useTranslation();

  return {
    mapper: (pricingModel?: string) => {
      switch (pricingModel) {
        case PricingModel.CHARGEABLE.toString():
          return t("platform.assignPlatformModal.pricingModelChargeable");
        case PricingModel.FREE.toString():
          return t("platform.assignPlatformModal.pricingModelFree");
        default:
          return t("platform.assignPlatformModal.pricingModelNotSpecified");
      }
    }
  };
}

export function useAfterUseModelMap() {
  const { t } = useTranslation();
  return {
    mapper: (afterUseModel?: string) => {
      switch (afterUseModel) {
        case AfterUseModel.MODEL_A.toString():
          return t("platform.assignPlatformModal.efaModel");
        default:
          return "-";
      }
    }
  };
}
